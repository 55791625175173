/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'layout-sidebar-reverse': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 3a2 2 0 00-2-2H2a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2zm-5-1v12H2a1 1 0 01-1-1V3a1 1 0 011-1zm1 0h2a1 1 0 011 1v10a1 1 0 01-1 1h-2z"/>',
    },
});
